@import "../../common/style/colors";
$transition: all ease;
$left-border: -960px;
$right-border: 1920px;
$top-border: 0;
$bottom-border: 100%;
.home-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.right-menu {
  position: absolute;
  top: 20px;
  right: 10%;
}

.preview-container {
  cursor: default;
  user-select: none;
  position: relative;
  height: 100%;
  width: 90%;
  margin: 0 5%;

  div {
    $default-size: 70px;
    $big-size: 1.3*$default-size;
    position: absolute;
    font-family: 'RoundedBookRegular', Roboto, serif;
    font-size: $default-size;
    white-space: nowrap;



    &.expertise {
      color: $white;
      left: 1*$default-size;
      top: 40%;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $left-border;
      }
    }

    &.ecoute {
      color: $dark-blue;
      font-size: $big-size;
      top: calc(40% - 2.5*#{$default-size});
      left: 4.5*$default-size;
      transform: rotate(-90deg);
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        top: $top-border;
      }
    }

    &.reactivite {
      color: $orange;
      top: calc(40% - 2.3*#{$default-size});
      transform: rotate(-90deg);
      left: 6*$default-size;
      opacity: 1;
      transition: $transition random(150) + ms;

      &.hidden {
        opacity: 0;
        top: $top-border;
      }
    }

    &.strategie {
      color: $orange;
      font-size: $big-size;
      top: 10%;
      left: 10*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $right-border;
      }
    }

    &.negociation {
      color: $dark-blue;
      top: 22%;
      left: 11*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $right-border;
      }
    }

    &.confiance {
      color: $white;
      font-size: $big-size;
      top: 30%;
      left: 9.5*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $right-border;
      }
    }

    &.savoir-faire {
      color: $white;
      top: 30%;
      transform: rotate(-90deg);
      left: 15.5*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        top: $top-border;
      }
    }

    &.teletravail {
      color: $dark-blue;
      top: 50%;
      left: 5*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $left-border;
      }
    }

    &.contestation {
      color: $white;
      font-size: $big-size;
      top: 60%;
      left: 0;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $left-border;
      }
    }

    &.licenciement {
      color: $orange;
      top: 72%;
      left: 5*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $left-border;
      }
    }

    &.solutions {
      color: $orange;
      transform: rotate(-90deg);
      top: 70%;
      left: 10*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        top: $right-border;
      }
    }

    &.prudhommes {
      color: $white;
      top: 72%;
      left: 14*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $right-border;
      }
    }

    &.contentieux {
      color: $orange;
      font-size: $big-size;
      top: 60%;
      left: 14*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $right-border;
      }
    }

    &.accidents {
      color: $dark-blue;
      top: 43%;
      left: 11*$default-size;
      opacity: 1;
      transition: $transition random(150) + 250  + ms;

      &.hidden {
        opacity: 0;
        left: $right-border;
      }
    }
  }

}

@media screen and (max-width: 1024px) {
  .preview-container {
    width: 98%;
    margin: 0 1%;

    div {
      $default-size: 40px;
      $big-size: 1.3*$default-size;
      font-size: $default-size;

      &.expertise {
        left: 1*$default-size;
        top: 40%;
      }

      &.ecoute {
        top: calc(40% - 2.5*#{$default-size});
        left: 4.5*$default-size;
        font-size: $big-size;
      }

      &.reactivite {
        top: calc(40% - 2.3*#{$default-size});
        left: 6*$default-size;
      }

      &.strategie {
        top: 20%;
        left: 10*$default-size;
        font-size: $big-size;
      }

      &.negociation {
        top: 27%;
        left: 11*$default-size;
      }

      &.confiance {
        top: 32%;
        left: 9.5*$default-size;
        font-size: $big-size;
      }

      &.savoir-faire {
        top: 30%;
        left: 15.5*$default-size;
      }

      &.teletravail {
        top: 48%;
        left: 5*$default-size;
      }

      &.contestation {
        top: 55%;
        left: 0;
        font-size: $big-size;
      }

      &.licenciement {
        top: 62%;
        left: 5*$default-size;
      }

      &.solutions {
        top: 58%;
        left: 10*$default-size;
      }

      &.prudhommes {
        top: 60%;
        left: 14*$default-size;
      }

      &.contentieux {
        top: 50%;
        left: 14*$default-size;
        font-size: $big-size;
      }

      &.accidents {
        top: 43%;
        left: 11*$default-size;
      }
    }
  }
}

@media screen and (max-device-width: 960px){
  .preview-container {
    width: 98%;
    margin: 0 1%;

    div {
      $default-size: 1.5*40px;
      $big-size: 1.3*$default-size;
      font-size: $default-size;

      &.expertise {
        color: $white;
        left: 5*$default-size;
        top: 18%;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          left: 5*$default-size;
          top: $top-border;
        }
      }

      &.ecoute {
        color: $dark-blue;
        font-size: $big-size;
        top: 30%;
        left: 7*$default-size;
        opacity: 1;
        transform: rotate(0deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: 30%;
          left: $right-border;
        }
      }

      &.reactivite {
        color: $orange;
        top: 35%;
        left: 7*$default-size;
        opacity: 1;
        transform: rotate(0deg);
        transition: $transition random(150) + ms;

        &.hidden {
          opacity: 0;
          top: 35%;
          left: $right-border;
        }
      }

      &.strategie {
        color: $orange;
        font-size: $big-size;
        top: 48%;
        left: 8*$default-size;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $bottom-border;
          left: 8*$default-size;
        }
      }

      &.negociation {
        color: $dark-blue;
        top: 55%;
        left: 6.5*$default-size;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $bottom-border;
          left: 6.5*$default-size;
        }
      }

      &.confiance {
        color: $white;
        font-size: $big-size;
        top: 50%;
        left: 5*$default-size;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $bottom-border;
          left: 5*$default-size;
        }
      }

      &.savoir-faire {
        color: $white;
        top: 79%;
        left: 6.8*$default-size;
        opacity: 1;
        transform: rotate(0deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: 79%;
          left: $right-border;
        }
      }

      &.teletravail {
        color: $dark-blue;
        top: 35%;
        left: 3*$default-size;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $top-border;
          left: 3*$default-size;
        }
      }

      &.contestation {
        color: $white;
        font-size: $big-size;
        top: 25%;
        left: 0;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $top-border;
          left: 0;
        }
      }

      &.licenciement {
        color: $orange;
        top: 35%;
        left: 0;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $top-border;
          left: 0;
        }
      }

      &.solutions {
        color: $orange;
        top: 50%;
        left: 2*$default-size;
        opacity: 1;
        transform: rotate(0deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: 50%;
          left: $left-border;
        }
      }

      &.prudhommes {
        color: $white;
        top: 65%;
        left: 0;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $bottom-border;
          left: 0;
        }
      }

      &.contentieux {
        color: $orange;
        font-size: $big-size;
        top: 75%;
        left: 1*$default-size;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $bottom-border;
          left: 1*$default-size;
        }
      }

      &.accidents {
        color: $dark-blue;
        top: 73%;
        left: $default-size;
        opacity: 1;
        transform: rotate(90deg);
        transition: $transition random(150) + 250  + ms;

        &.hidden {
          opacity: 0;
          top: $bottom-border;
          left: $default-size;
        }
      }
    }
  }
}
@import "./common/style/colors.scss";
@import "./common/style/sizes.scss";

body {
  font-family: 'Perpetua', Roboto, serif;
  font-weight: normal;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  background-color: $wallpaper-blue;

  height: 100vh;
}

#root {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
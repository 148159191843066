@import "../variables.scss";
@import "../../common/style/colors.scss";
@import "../../common/style/sizes.scss";

.top-layout {
  $title-size: 50px;

  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  right: 0;

  font-family: 'Perpetua', Roboto, serif;
  background-color: $white;
  width: 100%;
  height: $top-layout-height;
  border-bottom-left-radius: 0.75*$top-layout-height;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;

  .logo-container {
    flex: 1 0 0;
    margin-left: 0.5*$top-layout-height;
    .logo {
      cursor: pointer;
      user-select: none;
      height: 0.5*$top-layout-height;
      margin: 10px;
    }
  }

  .right-content {
    margin-right: 0.5*$top-layout-height;
    display: flex;

    .top-button {
      padding: 10px 0 10px 20px;
      &::before {
      border-bottom-left-radius: 20px;

      }
      &::after {
        border-bottom-left-radius: 20px;
      }

      &:hover {
        color: $simple-grey;
      }
    }

    .menu-icon {
      display: none;
      font-size: 2rem;
      color: $dark-blue;
    }

    .contact-button, .news-button {
      cursor: pointer;
      user-select: none;
      display: block;
      width: 2rem;
      height: 2rem;
      padding-right: 10px
    }
  }
}

.top-layout-shadow {
  z-index: 100;
  position: fixed;
  width: 50%;
  height: 8.5%;
  opacity: 0.5;
  box-shadow: 5px 10px 30px 40px $black;
  transform: rotate(-5deg);
  background-color: $black;
}

@media screen and (max-width: 1024px) {
  .top-layout-shadow {
    transform: rotate(-7.5deg);
  }
}

@media screen and (max-device-width: 960px){
  .top-layout {
    height: 1.5*$top-layout-height;

    .right-content {
      .menu-icon {
        display: block;
      }

      .contact-button {
        display: none;
      }
    }
  }

  .top-layout-shadow {
    display: none;
  }
}
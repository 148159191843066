@import "../variables.scss";
@import "../../common/style/colors.scss";

.container-layout {
  flex: 1;
  padding-top: $top-layout-height;
}

#background {
  position: fixed;
  z-index: -1;
  bottom: 40px;
  right: 40px;
  width: 75%;
  opacity: 0.1;
}

@media screen and (max-width: 1024px) {
  #background {
    width: 95%;
    right: 20px;
    //left: 20px;
    bottom: 20px;
  }
}

@media screen and (max-device-width: 960px) {
  .container-layout {
    padding-top: 0;
  }
}
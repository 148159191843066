@import "../../common/style/colors.scss";
@import "../../common/style/sizes.scss";

.page-container {
  height: 100%;

  min-width: 0;
  margin: 0 20% 0 7.5%;
  display: grid;
  grid-template-columns: 25% 10% auto;
  grid-auto-rows: 75px;
  grid-column-gap: 20px;

  .page-img {
    cursor: pointer;
    grid-column: 1;
    grid-row: 1 / 7;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 40px;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .page-menu {
    //background-color: red;
    grid-column: 1;
    grid-row: 7;
  }

  .page-title {
    //background-color: purple;
    font-family: RoundedBookRegular, Roboto, serif;
    grid-column: 2;
    grid-row: 1 / span 10;
    position: relative;

    div {
      color: $white;
      font-size: 2.5rem;
      white-space: nowrap;
      position: absolute;
      left: 50%;
      transform-origin: right;
      transform: translateX(-100%) translateY(calc(-50% + 40px)) rotate(-90deg);
    }

  }

  .page-content {
    //background-color: orange;
    grid-column: 3;
    grid-row: 1;


    &.full {
      grid-row: 1 / 11;
    }

    padding-top: 100px;
    font-family: Perpetua, Roboto, serif;
    font-size: 1.2rem;
    text-align: justify;
    color: $white;


  }

  .page-subtitle {
    position: relative;
    font-family: RoundedBookRegular, Roboto, serif;
    font-size: 1rem;
    text-align: justify;
    color: $white;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    &.underline::before {
      z-index: -1;
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      width: 1.5rem;
      height: 100%;
      left: 0;
      border-left: 1px solid;
      border-bottom: 1px solid;
      border-bottom-left-radius: 1rem;
    }
  }

  .page-buttons {
    //background-color: blue;
    grid-column: 3;
    grid-row: 6 / span 5;
  }
}

@media screen and (max-width: 1024px) {
  .page-container {
    margin: 0 10%;
    grid-template-columns: 33% 10% auto;

    .page-img {
      grid-row: 1 / 5;
    }

    .page-menu {
      grid-row: 5 / span 6;
    }
  }
}

@media screen and (max-device-width: 960px) {
  .page-container {
    overflow: inherit;
    margin: 0;
    grid-template-columns: 1fr 8fr;
    grid-auto-rows: 150px;
    .page-img {
      grid-column: 1 / 3;
      grid-row: 1 / 6;
      border-bottom-left-radius: 80px;

      img {
        object-position: 100% 10%;
      }
    }

    .page-menu {
      display: none;
      grid-row: 1 / 1;
    }

    .page-title {
      grid-column: 1;
      grid-row: 6 / 12;
    }

    .page-content {
      //display: none;
      //overflow: hidden;
      grid-column: 2 ;
      grid-row: 6 / 9;


      &.full {
        grid-row: 6 / 10;
      }
      margin-right: 50px;
      padding-top: 50px;
    }

    .page-subtitle {
    }

    .page-buttons {
      margin: 30px;
      grid-column: 1 / 3;
      grid-row: 9 / 12;
    }
  }
}
@import "../../common/style/colors.scss";
@import "../../common/style/sizes.scss";

.menu-container {
  font-family: "RoundedBookRegular", Roboto, serif;
  text-align: right;
  font-size: 2rem;
  font-weight: normal;
  color: $orange;
}

.menu-row {
  cursor: pointer;
  user-select: none;
  padding: 10px 0;

  &.huge {
    font-size: 2.2rem;
    padding-bottom: 8px;
  }

  &.selected {
    color: $white;
  }

  &:hover {
    color: $white;
  }

  .menu-title {

  }

  .subtitle {
    font-size: 1.5rem;
  }
}

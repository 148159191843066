@import "../../common/style/colors.scss";

div a, p a {
  text-decoration: none;
  color: $white;
}

.block {
  display: flex;
  .block-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 5px;

    &.material {
      background-color: $white;
      color: $dark-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 0.5rem;
      box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.4);
    }

    img {
      border-bottom-left-radius: 0.5rem;
      box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.4);
    }
  }
  .access-title {
  }
  ul {
    margin-top: 0;
    padding-left: 1.5rem;
  }
}

@media screen and (max-device-width: 960px) {
  .img-contain {
    .page-img {
      grid-row: 1 / 8;
    }

    .page-title {
      grid-row: 8 / 12;
    }

    .page-content {
      grid-row: 8 / 12;

      &.full {
        grid-row: 8 / 12;
      }
    }
  }
}
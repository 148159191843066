@import "../../common/style/colors.scss";

.mentions {
  margin:  40px auto;
  width: 50%;
  color: $white;
  font-size: 1rem;

  p {
    margin: 2.5rem 0;
  }
  .page-subtitle {
    position: relative;
    font-family: RoundedBookRegular, Roboto, serif;
    font-size: 1rem;
    text-align: justify;
    color: $white;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;

    &.underline::before {
      z-index: -1;
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      width: 1.5rem;
      height: 100%;
      left: 0;
      border-left: 1px solid;
      border-bottom: 1px solid;
      border-bottom-left-radius: 1rem;
    }
  }
}

@media screen and (max-device-width: 960px) {
  .mentions {
    width: 80%;
    margin: 200px auto;
  }
}

.form {
  text-decoration: underline;
}
@import "../../common/style/colors.scss";
@import "../../common/style/sizes.scss";

.modal-background {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  display: none;
  justify-content: center;
  align-items: center;
  animation: fadein 250ms;

  &.open {
    display: flex;
  }

  background-color: rgba($black, 0.8);

  .modal {
    width: 40%;
    background-color: $white;
    opacity: 1 !important;
    font-size: 1rem;
    border-bottom-left-radius: 2.5rem;

    .modal-header {
      font-size: 1.5rem;
      font-family: RoundedBookRegular, Roboto, serif;
      background-color: $wallpaper-blue;
      padding: 10px 10%;
      text-align: center;
      color: $white;
      border-bottom-left-radius: 2.5rem;

      .subtitle {
        font-size: 1rem;
      }
    }

    .modal-content {
      font-size: 1rem;
      font-family: Perpetua serif;
      text-align: left;
      max-height: 400px;
      padding: 10px 40px;

      overflow-y: auto;

      .modal-body {
        li {
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and  (max-width: 1024px) {
  .modal-background .modal {
    width: 50%;
  }
}

@media screen and (max-device-width: 960px) {
  .modal-background .modal {
    width: 80%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

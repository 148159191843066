@import "../../common/style/colors.scss";
@import "../../common/style/sizes.scss";

.button-container {
  flex: 1 auto 0;
  position: relative;
  font-weight: bold;
  font-family: RoundedBookRegular, Roboto, serif;
  font-size: 1rem;
  color: $orange;

  cursor: pointer;
  user-select: none;

  text-align: left;
  min-height: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  $radius: 40px;

  &::before {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 45px;
    height: 100%;
    left: 0;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-bottom-left-radius: $radius;
  }

  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: $radius + 5px;
    height: $radius +5px;
    border-bottom-left-radius: $radius;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    color: $white;
  }
}

.button-list {
  //background-color: green;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}


@media screen and (max-device-width: 960px) {
  .button-list {
    width: 100%;
  }
}
@import "../../common/style/colors.scss";

.footer {
  font-size: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;

  a {
    color: fade-out($white, 0.8);
    transition: color ease-in-out 250ms;

    &:hover{
      color: $white;
    }
  }
}